<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Extensive Weidehaltung</h1>
      <p>
        Da es unser Konzept ist die Natur nachzuahmen, schauen wir uns genau an wie eine natürliche Umgebung für
        unsere Nutztiere aussehen kann und erschaffen eine solche. Die Tiere können als Herde oder als
        Rotte durch unser Land streifen. Wir legen Wege an, die ihnen Zugang zu Zonen verschaffen, in denen
        sie entweder grasen oder den Boden gemäß ihrer natürlichen Veranlagung durchwühlen können.
        Diese Wege führen sie auch zu Wasserquellen oder Orten, an denen Ergänzungsfutter erhältlich ist.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2021-04-19_10-41-20.jpg">
        <img src="../../assets/images/2021-04-19_10-41-20.jpg">
      </picture>

      <p>
        Obwohl die EU-Verordnung 2018/848 über die ökologische Produktion die Verwendung von externen Futtermitteln
        auf unbestimmte Zeit erlaubt, folgen wir dem Geist dieser Verordnung und arbeiten daran die Tiere von dem Land
        zu ernähren, auf dem sie leben. Es wird einige Zeit dauern bis wir dieses Ziel erreichen, da wir von
        <router-link :to="{ name: 'desertification' }"> Wüstenbildung</router-link> bedroht sind. Aber genau dieselben
        Tiere können bei korrekter Haltung dabei helfen diesen Vorgang zu stoppen. Sehen Sie unten was wir den <a
          href="#feed_for_pigs">Schweinen</a> und <a href="#feed_for_cattle">Rindern</a>
        füttern, wenn auf unserem Land noch nicht genug wächst.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-05-18_13-18-34.jpg">
        <img src="../../assets/images/2022-05-18_13-18-34.jpg">
      </picture>

      <h2>Tierische Produkte</h2>
      <p>
        Die Tiere sind nicht unser Hauptprodukt. Wir sehen sie als Teil des Ökosystems, das wir wiederherstellen wollen,
        und daher ist ihre Funktion im System für uns viel wertvoller als ein möglicher Verkauf. Aus diesem Grund
        ist es unser Ziel jedes Tier so lange wie möglich im System zu halten.
      </p>
      <p>
        Aber auch der Tod gehört zu jedem Ökosystem dazu. Zum Beispiel töten die Bullen in einer Rinderherde ein anderes
        Herdenmitglied, das blutet, um die Herde vor Fressfeinden zu schützen. Tiere werden auch krank, brechen sich ein
        Bein und es gibt kein Heilmittel. In den meisten Fällen kann das Fleisch solcher Tiere von Menschen gegessen
        werden. Es ist nicht immer notwendig gesunde Tiere aktiv zu töten, um Fleisch zu gewinnen, es sei denn, die
        Fleischproduktion und der Verkauf sind das primäre Ziel des Betriebes.
      </p>
      <p>
        Wir arbeiten mit den örtlichen Behörden zusammen, um eine schmerzlose Form der Schlachtung auf dem Bauernhof
        gemäß den Vorschriften der Europäischen Union durchführen zu dürfen, anstatt Tiere zu einem riesigen, mehrere
        Stunden entfernten Schlachthof zu transportieren. Das ist ein komplizierter Prozess, da nicht nur die Behörden
        des Landwirtschaftsministeriums, sondern auch Tierärzte, die für die Gesundheitsbehörden arbeiten, involviert
        sind. Was dies noch komplizierter macht, ist die Tatsache, dass die Regeln für die große Agrarindustrie gemacht
        sind.
      </p>
      <p>
        Da wir von <router-link :to="{ name: 'desertification' }">Versteppung</router-link> bedroht sind
        verwenden wir Tierhaltung, um das Land zu heilen und es fruchtbarer zu machen - ganz so wie z.B.
        <em>Holistic Management</em> und die Lehren von Alan Savory und anderen es beschreiben.
      </p>

      <YouTubeLink />

      <h2>Externes Futter</h2>
      <p>
        Wenn wir nicht genug Futter für die Tiere haben, kaufen wir Futter von außerhalb. Sie können sehen, was darin
        enthalten ist. Das Futter enthält einige Vitamine, aber kein Vitamin B12.
      </p>
      <h3 id="feed_for_pigs">Externes Futter für iberische Schweine</h3>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-piensoCerdoIberico.jpg">
        <img width="100%" src="../../assets/images/piensoCerdoIberico.jpg">
      </picture>
      <h3 id="feed_for_cattle">Externes Futter für Rinder</h3>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-piensoBovinoExtensivo.jpg">
        <img width="100%" src="../../assets/images/piensoBovinoExtensivo.jpg">
      </picture>


    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "AnimalsDE",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
